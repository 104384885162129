import React from "react";
import { Box, Typography } from "@mui/material";
import DropDownMenu from "../DropDownMenu";
import { boxFlexCenterStyle } from "../../data/data";
import { cutTypes, slideDirections, slideHandleTypes, slideLockPositions } from "../../data/selectedTabsData";
import ToggleButtons from "../ToggleButtons";

export function SlideSelected({payload}: {payload: any}) {
    const handleDropDownChangeCutType = (selectedValue: string) => {
        const numberValue = parseInt(selectedValue);
        if(!isNaN(numberValue)) {
            payload.jointWay = numberValue;
        }
    };

    const handleSlideDirection = (selectedValue: string) => {
        if(selectedValue === "fixed") {
            payload.isFixed = true;
        } else {
            payload.isFixed = false;
            payload.openDirection = selectedValue;
        }
    };

    const handleSlideLockPosition = (selectedValue: string) => {
        const numberValue = parseInt(selectedValue);
        if(!isNaN(numberValue)) {
            payload.slideLockHidden = numberValue;
        }
    };

    const handleChangeSlideHandleType = (selectedValue: string) => {
        if(selectedValue === "hidden") {
            payload.slideHandleHidden = true;
            return;
        }
        payload.slideHandleHidden = false;
        payload.slideHandleType = selectedValue;
    };

    return (
        <Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Tipo de Corte</Typography>
                <DropDownMenu 
                    items={cutTypes}
                    onChange={handleDropDownChangeCutType}
                    startValue={payload.jointWay as string}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography style={{ flex: 1 }}>Direção</Typography>
                <ToggleButtons
                    defaultValue={payload.isFixed ? "fixed" : payload.openDirection}
                    items={slideDirections}
                    onChange={handleSlideDirection}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Tipo<br/>Puxador</Typography>
                <DropDownMenu 
                    items={slideHandleTypes}
                    onChange={handleChangeSlideHandleType}
                    startValue={payload.slideHandleType}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography style={{ flex: 1 }}>Número<br/>de Travas</Typography>
                <DropDownMenu 
                    items={slideLockPositions}
                    onChange={handleSlideLockPosition}
                    startValue={payload.slideLockHidden as string}
                />
            </Box>
        </Box>
    );
}