import {
    CropSquare,
    BrowserNotSupported,
    ExitToApp,
    DoorBack,
    EditNote,
    Straighten,
    ChangeHistory,
    FormatAlignJustify,
    DoorSlidingOutlined,
    CircleOutlined,
    Polyline,
    DevicesFold,
    Grid4x4,
    Square,
    SubdirectoryArrowRight,
    ThreeSixty,
    ViewWeek,
    CalendarViewWeek,
    VerticalAlignCenter,
} from "@mui/icons-material";

import { LeftTabData } from "./interfaces/leftTabData";
import { RightTabData } from "./interfaces/rightTabData";
import { GlassTab } from "../components/rightTabComponents/GlassTab";
import { ProfileTab } from "../components/rightTabComponents/ProfileTab";
import { SelectedTab } from "../components/rightTabComponents/SelectedTab";
import { WebccRuntime } from "./interfaces/WebccInterfaces/WebccRuntime";

export const linhas = [
    { label: "Linha 1", value: "0" },
    { label: "Linha 2", value: "1" },
    { label: "Linha 3", value: "2" },
    { label: "Linha 4", value: "3" },
];

export const referencias = [
    { label: "Referencia 1", value: "0" },
    { label: "Referencia 2", value: "1" },
    { label: "Referencia 3", value: "2" },
    { label: "Referencia 4", value: "3" },
];

export const MAX_PROFILE_WIDTH = 300;

export const boxFlexCenterStyle = {
    display: "flex",
    margin: "10px 0px",
    alignItems: "center",
    justifyContent: "space-between",
    width: "265px"
};

const selectTool = (cc: WebccRuntime, setSelectedTool: (data: string) => void, toolName: string, customName?: string) => {
    cc.toolManager.takeTool(toolName);
    customName ? setSelectedTool(customName) : setSelectedTool(toolName);
};

export const leftTabs: LeftTabData[] = [
    {
        label: "Esquadrias",
        accordions: [
            {
                name: "Contornos",
                content: [ 
                    { component: CropSquare, tip: "Janela", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "frame_scalable") },
                    { component: DoorBack, tip: "Porta", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "door") },
                    { component: Polyline, tip: "Frame Mão Livre", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "frame_polygon") },
                    { component: BrowserNotSupported, tip: "Montante/Travessa", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "mullion_cross_line") },
                    { component: VerticalAlignCenter, tip: "Montante/Travessa Equalizada", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "mullion_cross_line_equal_two") },
                    { component: ViewWeek, tip: "Conector", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "connerJoiner") },
                ],
            },
            {
                name: "Modelos",
                content: [
                    { component: ChangeHistory, tip: "Abrir", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "sash") },
                    { component: SubdirectoryArrowRight, tip: "Oscilobatente", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "sash", "oscilobatente") },
                    { component: DevicesFold, tip: "Camarão", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "foldSash") },
                    { component: DoorSlidingOutlined, tip: "Abrir dupla", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "doubleSash") },
                    { component: ThreeSixty, tip: "Pivotante", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "sash", "pivotante") },
                    { component: ExitToApp, tip: "Correr", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "slide") },
                    { component: CropSquare, tip: "Fixo", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "sash", "fixo") },
                    { component: FormatAlignJustify, tip: "Preenchimento Veneziana", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "fillerShade") },
                    { component: Square, tip: "Preenchimento Painel", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "fillerPanel") },
                    { component: Grid4x4, tip: "Preenchimento Tela", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "fillerScreen") },
                    { component: CalendarViewWeek, tip: "Preenchimento Grade", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "antiTheft") },
                ]
            },
            {
                name: "Cotas e Anotações",
                content: [
                    { component: Straighten, tip: "Cota Extra", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "extraDimArbitrary") },
                    { component: EditNote, tip: "Anotação", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "note") },
                ],
            },
        ]
    },

    {
        label: "Vidro Temperado",
        accordions: [
            {
                name: "Contorno",
                content: [
                    { component: CropSquare, tip: "Frame", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "frame_scalable") },
                    { component: Polyline, tip: "Frame Mão Livre", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "frame_polygon") },
                ]    
            },
            {
                name: "Modelos",
                content: [
                    { component: ChangeHistory, tip: "Abrir", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "sash") },
                    { component: DoorSlidingOutlined, tip: "Abrir dupla", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "doubleSash") },
                    { component: ExitToApp, tip: "Correr", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "slide") },
                ]
            },
            {
                name: "Componentes",
                content: [
                    { component: CircleOutlined, tip: "Furo no Vidro", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "glassHole") },
                ],
            },
            {
                name: "Cotas e Anotações",
                content: [
                    { component: Straighten, tip: "Cota Extra", toolAction: (cc, setSelectedTool) => selectTool(cc, setSelectedTool, "extraDimArbitrary") },
                    { component: EditNote, tip: "Anotação", toolAction: (cc, setSelectedTool) => selectTool(cc,setSelectedTool, "note") },
                ],
            },
        ]
    }
];

export const rightTabs: RightTabData[] = [
    {
        label: "Perfis",
        content: ProfileTab,
    },
    {
        label: "Vidros",
        content: GlassTab,
    },
    {
        label: "Selecionado",
        content: SelectedTab,
    }
];