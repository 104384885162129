export const openToward = [
    { label: "Fora", value: "outward" },
    { label: "Dentro", value: "inward" }
];

export const slideDirections = [
    { label: "Fixa", value: "fixed" },
    { label: "Esquerda", value: "left" },
    { label: "Direita", value: "right" }
];

export const foldDirection = [
    { label: "Horizontal", value: "horizontal" },
    { label: "Vertical", value: "vertical" }
];

export const cutTypes = [
    { label: "45º", value: "0" },
    { label: "Horizontal", value: "1" },
    { label: "Vertical", value: "2" },
];

export const handlePositions = [       
    { label: "Nenhum", value: "none" },
    { label: "Direita", value: "right" },
    { label: "Esquerda", value: "left" },
    { label: "Baixo", value: "down" },
    { label: "Cima", value: "up" },
    { label: "Esquerda e Cima", value: "left_with_up" },
    { label: "Esquerda e Baixo", value: "left_with_down" },
    { label: "Direita e Cima", value: "right_with_up" },
    { label: "Direita e Baixo", value: "right_with_down" },
    { label: "Pivotante Direita", value: "Right_Rotate" },
    { label: "Pivotante Esquerda", value: "Left_Rotate" },
    { label: "Pivotante Cima", value: "Up_Rotate" },
    { label: "Pivotante Baixo", value: "Down_Rotate" },
];

export const handleTypes = [
    { label: "Nenhum", value: "hidden"},
    { label: "1", value: "Handle" },
    { label: "2", value: "Handle2" },
    { label: "3", value: "Handle3" },
    { label: "4", value: "Handle4" },
    { label: "5", value: "Handle5" },
    { label: "C1", value: "CommercialHandle" },
    { label: "C2", value: "CommercialHandle2" },
    { label: "C3", value: "CommercialHandle3" },
    { label: "C4", value: "CommercialHandle4" },
    { label: "C5", value: "CommercialHandle5" },
    { label: "C6", value: "CommercialHandle6" },
    { label: "C7", value: "CommercialHandle7" },
    { label: "C8", value: "CommercialHandle8" },
    { label: "C9", value: "CommercialHandle9" },
    { label: "C10", value: "CommercialHandle10" },
    { label: "C11", value: "CommercialHandle11" },
    { label: "C12", value: "CommercialHandle12" },
    { label: "C13", value: "CommercialHandle13" },
    { label: "C14", value: "CommercialHandle14" },
    { label: "Cross", value: "CrossHandle" },
];

export const slideHandleTypes = [
    { label: "Nenhum", value: "hidden" },
    { label: "1", value: "HandleForSlide" },
    { label: "2", value: "HandleForSlide2" },
    { label: "3", value: "HandleForSlide3" },
];

export const slideLockPositions = [
    { label: "Esconder", value: "0" },
    { label: "Único", value: "1" },
    { label: "Duplo", value: "2" }
];

export const numberSashesData = [
    { label: "2 folhas", value: "2"},
    { label: "3 folhas", value: "3"},
    { label: "4 folhas", value: "4"},
    { label: "5 folhas", value: "5"},
    { label: "6 folhas", value: "6"},
    { label: "8 folhas", value: "8"},
    { label: "Atrás da parede", value: "0"},
];

export const hingeOptions = [
    { label: "Nenhuma", value: "0"},
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
];

export const panelCutStyles = [
    { label: "Nenhum", value: "None" },
    { label: "Horizontal", value: "Horizontal" },
    { label: "Vertical", value: "Vertical" },
];

interface ImgData {
    path: string;
    index: number;
}

export const mappedSlideImages: { [key: string]: ImgData[] } = {
    "2": [
        {path: "2folhas.png", index: 15},
        {path: "2folhas_2.png", index: 0},
        {path: "2folhas_3.png", index: 7},
        {path: "2folhas_4.png", index: 1},
    ],
    "3": [
        {path: "3folhas.png", index: 20},
        {path: "3folhas_2.png", index: 6},
        {path: "3folhas_3.png", index: 36},
        {path: "3folhas_4.png", index: 43},
    ],
    "4": [
        {path: "4folhas.png", index: 2},
        {path: "4folhas_2.png", index: 3},
        {path: "4folhas_3.png", index: 9},
        {path: "4folhas_4.png", index: 23},
        {path: "4folhas_5.png", index: 27},
    ],
    "5": [
        {path: "5folhas.png", index: 78},
    ],
    "6": [
        {path: "6folhas.png", index: 42},
        {path: "6folhas_2.png", index: 60},
        {path: "6folhas_3.png", index: 83},
    ],
    "8": [
        {path: "8folhas.png", index: 119},
        {path: "8folhas_2.png", index: 122}
    ],
    "0": [
        {path: "atrasParede.png", index: 12},
        {path: "atrasParede_5.png", index: 30},
        {path: "atrasParede_2.png", index: 88},
        {path: "atrasParede_3.png", index: 90},
        {path: "atrasParede_4.png", index: 111},
    ]
};

export const tiposEqualizacao = [
    { label: "Ambos os lados", value: "0" },
    { label: "Cima/Esquerda", value: "1" },
    { label: "Baixo/Direita", value: "2" },
];

export const DEFAULT_PROFILE_WIDTH = 60;
export const DEFAULT_MULLION_WIDTH = 90;