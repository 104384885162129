import { boxFlexCenterStyle } from "../../data/data";
import { Box, Typography } from "@mui/material";
import DropDownMenu from "../DropDownMenu";
import { mappedSlideImages, cutTypes, numberSashesData, slideHandleTypes } from "../../data/selectedTabsData";
import { useState } from "react";
import { CustomSwitch } from "../Switches";
import React from "react";

export function SlideGroupSelected({payload}: {payload: any}) {
    const [imgsToRender, setImgsToRender] = useState(mappedSlideImages["2"]);

    const handleDropDownChangeCutType = (selectedValue: string) => {
        const numberValue = parseInt(selectedValue);
        if(!isNaN(numberValue)) {
            payload.jointWay = numberValue;
        }
    };

    const handleImageClick = (clickedIndex: number) => {
        payload.appliedOptionIndex = clickedIndex;
    };

    const handleNumberSashesChange = (selectedValue: string) => {
        setImgsToRender(mappedSlideImages[selectedValue]);
        payload.appliedOptionIndex = mappedSlideImages[selectedValue][0].index;
    };

    const handleChangeSlideHandleType = (selectedValue: string) => {
        if(selectedValue === "hidden") {
            payload.handleHidden = true;
            return;
        }
        payload.hidden = false;
        payload.handleType = selectedValue;
    };

    const handleShowLock = (selectedValue: boolean) => {
        payload.lockHidden = !selectedValue;
    };

    return (
        <Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Tipo de Corte</Typography>
                <DropDownMenu 
                    items={cutTypes}
                    onChange={handleDropDownChangeCutType}
                    startValue={payload.jointWay as string}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Mostrar<br/>Travas</Typography>
                <CustomSwitch
                    startValue={!payload.lockHidden}
                    onChange={handleShowLock}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Tipo<br/>Puxador</Typography>
                <DropDownMenu 
                    items={slideHandleTypes}
                    onChange={handleChangeSlideHandleType}
                    startValue={payload.handleType}
                />
            </Box>
            <Box>
                <Typography>Quantidade de Folhas:</Typography>
                <DropDownMenu 
                    items={numberSashesData}
                    onChange={handleNumberSashesChange}
                />
            </Box>
            <Box marginTop="15px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                {imgsToRender.map((imgData) => (
                    <img
                        style={{ cursor: "pointer", margin: "5px 0px" }}
                        width={275}
                        height={50}
                        key={imgData.index}
                        src={"/assets/slideOptions/" + imgData.path}
                        alt={`Imagem ${imgData.index}`}
                        onClick={() => handleImageClick(imgData.index)}
                    />
                ))}
            </Box>
        </Box>
    );
}