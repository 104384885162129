import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconData } from "../data/interfaces/iconData";
import { Box, Button, Tooltip } from "@mui/material";
import { useContext } from "react";
import { WebccContext } from "../contexts/WebccContext";

const accordion_width = 300;

interface ExpanderProps {
  name: string;
  content: IconData[];
}

export default function Expander(props: ExpanderProps) {
  const { cc, setSelectedTool } = useContext(WebccContext);

  return (
    <Box sx={{ width: accordion_width }}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>{props.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)" }}>
            {props.content.map((content, index) => (
              <Tooltip key={index} title={content.tip} disableInteractive>
                <Button 
                  onClick={ () => content.toolAction(cc, setSelectedTool) }>
                    <content.component sx={{ fontSize: 32, transform: content.tip === "Montante/Travessa Equalizada" ? "rotate(90deg)" : "" }}/>
                </Button>
              </Tooltip>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}