import { WebccRuntime } from "../data/interfaces/WebccInterfaces/WebccRuntime";

export const openJSON = (cc: WebccRuntime, config: string) => {
  cc.shapeManager.openFile(config, false);
  const configObj = JSON.parse(config);
  if (configObj.sm[0]) {
    const colorManager = configObj.sm[0].cm;
    cc.shapeManager.barNormal = colorManager.bn;
    cc.shapeManager.glassColor = colorManager.g;
  }
};
