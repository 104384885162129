import React from "react";
import { boxFlexCenterStyle } from "../../data/data";
import { Box, Typography } from "@mui/material";
import DropDownMenu from "../DropDownMenu";
import ToggleButtons from "../ToggleButtons";
import { openToward, cutTypes, hingeOptions, handleTypes } from "../../data/selectedTabsData";
import { CustomSwitch } from "../Switches";

export function SashGroupSelected({payload}: {payload: any}) {
    const handleDropDownChangeCutType = (selectedValue: string) => {
        const numberValue = parseInt(selectedValue);
        if(!isNaN(numberValue)) {
            payload.jointWay = numberValue;
        }
    };

    const handleChangeHandleType = (selectedValue: string) => {
        payload.handleType = selectedValue;
    };

    const handleChangeOpenDirection = (selectedValue: string) => {
        payload.openToward = selectedValue;
    };

    const handleOpenOrClose = (selectedValue: boolean) => {
        payload.opened = selectedValue;
    };

    const handleChangeHingeNumber = (selectedValue: string) => {
        const numberValue = parseInt(selectedValue);
        if(!isNaN(numberValue)) {
            payload.hingeCount = numberValue;
        }
    };

    return (
        <Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Tipo de Corte</Typography>
                <DropDownMenu 
                    items={cutTypes}
                    onChange={handleDropDownChangeCutType}
                    startValue={payload.jointWay as string}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Tipo<br/>Puxador</Typography>
                <DropDownMenu 
                    items={handleTypes}
                    onChange={handleChangeHandleType}
                    startValue={payload.handleType}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Dobradiças:</Typography>
                <DropDownMenu 
                    items={hingeOptions}
                    onChange={handleChangeHingeNumber}
                    startValue={payload.hingeCount as string}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography style={{ flex: 1 }}>Abre para:</Typography>
                <ToggleButtons
                    defaultValue={payload.openToward}
                    items={openToward}
                    onChange={handleChangeOpenDirection}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Abrir</Typography>
                <CustomSwitch
                    startValue={payload.opened}
                    onChange={handleOpenOrClose}
                />
            </Box>
        </Box>
    );
}