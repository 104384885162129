import React from "react";
import { Box } from "@mui/material";
import { ReactNode } from "react";
import { StackScroll } from "./StackScroll";




interface CustomDrawerProps {
  children: ReactNode;
}

export function CustomDrawer({children}: CustomDrawerProps) {
  return (
    <StackScroll py={3} flex={1}>
			<Box display="flex" flexDirection="column" flex={1}>
				{children}
			</Box>
		</StackScroll>
  );
}