import React from "react";
import { Box, Typography } from "@mui/material";
import DropDownMenu from "../DropDownMenu";
import { boxFlexCenterStyle } from "../../data/data";
import ToggleButtons from "../ToggleButtons";
import { openToward, cutTypes, handlePositions, hingeOptions, handleTypes } from "../../data/selectedTabsData";
import { CustomSwitch } from "../Switches";

export function SashSelected({payload}: {payload: any}) {
    const handleDropDownChangeCutType = (selectedValue: string) => {
        const numberValue = parseInt(selectedValue);
        if(!isNaN(numberValue)) {
            payload.jointWay = numberValue;
        }
    };

    const handleChangeOpenToward = (selectedValue: string) => {
        payload.openToward = selectedValue;
    };

    const handleChangeHandlePosition = (selectedValue: string) => {
        const toward = `${payload.openToward}`;
        payload.openDirection = selectedValue;
        payload.openToward = toward;
        payload.handleHidden = selectedValue === "none" || payload.handleHidden;
    };

    const handleChangeHandleType = (selectedValue: string) => {
        if(selectedValue === "hidden") {
            payload.handleHidden = true;
            return;
        }
        payload.handleType = selectedValue;
    };

    const handleOpenOrClose = (selectedValue: boolean) => {
        payload.opened = selectedValue;
    };
    
    const handleChangeHingeNumber = (selectedValue: string) => {
        const numberValue = parseInt(selectedValue);
        if(!isNaN(numberValue)) {
            payload.hingeCount = numberValue;
        }
    };

    return (
        <Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Tipo de Corte</Typography>
                <DropDownMenu 
                    items={cutTypes}
                    onChange={handleDropDownChangeCutType}
                    startValue={payload.jointWay as string}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Abre<br/>Para</Typography>
                <DropDownMenu 
                    items={handlePositions}
                    onChange={handleChangeHandlePosition}
                    startValue={payload.openDirection}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Tipo<br/>Puxador</Typography>
                <DropDownMenu 
                    items={handleTypes}
                    onChange={handleChangeHandleType}
                    startValue={payload.handleType}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Dobradiças:</Typography>
                <DropDownMenu 
                    items={hingeOptions}
                    onChange={handleChangeHingeNumber}
                    startValue={payload.hingeCount as string}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography style={{ flex: 1 }}>Abre para:</Typography>
                <ToggleButtons
                    defaultValue={payload.openToward}
                    items={openToward}
                    onChange={handleChangeOpenToward}
                />
            </Box>
            {!payload.isFold && <Box style={boxFlexCenterStyle}>
                <Typography>Abrir</Typography>
                <CustomSwitch
                    startValue={payload.opened}
                    onChange={handleOpenOrClose}
                />
            </Box>}
        </Box>
    );
}