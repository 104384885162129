import React from "react";
import { useState, MouseEvent } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ButtonData } from "../data/interfaces/buttonData";
import { theme } from "../styles/theme";
import { ArrowDropDown } from "@mui/icons-material";

interface DropDownButtonsProps {
  buttonsData: ButtonData[];
  label: string;
}

export function DropdownButtons(props: DropDownButtonsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button 
        onClick={handleClick}
        variant="outlined"
        size="small"
        style={ 
          {
            color: theme.palette.primary.contrastText,
            borderColor: theme.palette.primary.contrastText
          }
        }
        endIcon={<ArrowDropDown/>}
      >
        {props.label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.buttonsData.map((button, index) => (
          <MenuItem key={index} onClick={button.onClick}>
            {button.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
