import { boxFlexCenterStyle } from "../../data/data";
import { foldDirection } from "../../data/selectedTabsData";
import { Box, TextField, InputAdornment, Typography } from "@mui/material";
import ToggleButtons from "../ToggleButtons";
import React from "react";

export function handleGapRange(value: number) {
    return value <= 44 || value > 500 ? 150 : value;
}

export function AntiTheftSelected({payload}: {payload: any}) {
    const handleIntervalBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const numberValue = parseInt(event.target.value);
        if(!isNaN(numberValue)) {
            const newGap = handleGapRange(numberValue);
            payload.gap = newGap;
            event.target.value = String(newGap);
        }
    };

    const handleChangeDirection = (selectedValue: string) => {
        payload.orientation = selectedValue;
    };

    return (
        <Box>
            <Box style={boxFlexCenterStyle}>
                <TextField
                    variant="outlined" size="small"
                    label="Tamanho Invervalo"
                    onBlur={handleIntervalBlur}
                    defaultValue={String(payload.gap)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    }}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Direção</Typography>
                <ToggleButtons
                    defaultValue={payload.orientation.startsWith("h") || payload.orientation.startsWith("H") ? "horizontal" : "vertical"}
                    items={foldDirection}
                    onChange={handleChangeDirection}
                />
            </Box>
        </Box>
    );
}