import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useContext, ReactNode } from "react";
import { RightTabData } from "../data/interfaces/rightTabData";
import { SidebarContext } from "../contexts/sidebarContext";

interface PanelProps {
  children: ReactNode,
  value: number,
  index: number,
}

function TabPanel(props: PanelProps) {
  return (
    <Box>
      {
        props.value === props.index && (
          props.children
        )
      }
    </Box>
  );
}

interface CustomTabProps {
  tabs: RightTabData[];
}

export function RightTab(props: CustomTabProps) {
  const {rightTabIndex, setRightIndex} = useContext(SidebarContext);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setRightIndex(newValue);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Tabs value={rightTabIndex} onChange={handleChange}>
        {props.tabs.map((tab) => (
          <Tab key={tab.label} label={tab.label} />
        ))}
      </Tabs>
      
      {props.tabs.map((tab, i) => (
        <TabPanel key={tab.label} value={rightTabIndex} index={i}>
          <tab.content ></tab.content>
        </TabPanel>
      ))}
    </Box>
  );
}