import { WebccRuntime } from "../data/interfaces/WebccInterfaces/WebccRuntime";
import * as d3 from "d3";
import { Dim } from "../data/interfaces/WebccInterfaces/dim";
import { Frame } from "../data/interfaces/WebccInterfaces/frame";
import { Point } from "../data/interfaces/point";
import { Note } from "../data/interfaces/WebccInterfaces/note";
import { Child } from "../data/interfaces/WebccInterfaces/child";
import { screenB64 } from "../data/screenb64Data";

type CircleData = {
    cx: number;
    cy: number;
    r: number;
};

type Segment = {
    p1: Point;
    p2: Point;
}

type TextSettings = {
    fill?: string;
    transformData?: string;
    fontWeight?: string;
    fontSize?: number;
}

export class SVGConverter {
    private svg: SVGSVGElement | null;
    private dimsToDraw: Dim[];
    private shapem: Frame[];
    private couples: Child[];
    private notes: Note[];
    private barColor: string;
    private glassColor: string;
    private dimTotalHeight: Dim | undefined;
    private dimTotalWidth: Dim | undefined;
    private dimFontSize: number;
    private minX: number;
    private minY: number;
    private maxX: number;
    private maxY: number;
    private hasIncludedScreenImage: boolean;

    constructor(cc: WebccRuntime) {
        this.svg = d3.create("svg")
            .node();

        this.dimsToDraw = [];        
        this.shapem = cc.shapeManager.shapem;
        this.couples = cc.shapeManager.couples;
        this.notes = cc.shapeManager.notes;
        this.barColor = cc.shapeManager.barNormal;
        this.glassColor = cc.shapeManager.glassColor;
        this.dimTotalHeight = cc.shapeManager.totalHeightDim ?? undefined;
        this.dimTotalWidth = cc.shapeManager.totalWidthDim ?? undefined;
        this.dimFontSize = cc.shapeManager.dimFontSize;
        this.minX = cc.shapeManager.windowWithDimBox.xmin;
        this.minY = cc.shapeManager.windowWithDimBox.ymin;
        this.maxX = cc.shapeManager.windowWithDimBox.xmax;
        this.maxY = cc.shapeManager.windowWithDimBox.ymax;
        this.hasIncludedScreenImage = false;
    }

    private addCircle(circleData: CircleData, position: Point, fill?: string, transformData?: string) {
        const circle = d3.select(this.svg).append("circle")
            .attr("cx", circleData.cx)
            .attr("cy", circleData.cy)
            .attr("r", circleData.r)
            .attr("stroke", "black")
            .attr("stroke-width", 2);
        if(fill)
            circle.attr("style", `fill: ${fill};`);
        if(transformData) {
            transformData = `translate(${position.x},${position.y}) ` + transformData;
            circle.attr("transform", transformData);
        } else {
            circle.attr("transform", `translate(${position.x},${position.y})`);
        }
    }

    private addLine(segment: Segment, dashed=false, strokeColor?: string, strokeWidth=2) {
        const svg = d3.select(this.svg).append("line")
            .attr("x1", segment.p1.x)
            .attr("y1", segment.p1.y)
            .attr("x2", segment.p2.x)
            .attr("y2", segment.p2.y)
            .attr("stroke-width", strokeWidth);
        
        if(dashed) {
            svg.style("stroke-dasharray", "20,10");
        }
        if(strokeColor) {
            svg.attr("stroke", strokeColor);
        } else {
            svg.attr("stroke", "black");
        }
    }

    private addPath(pathPoints: Point[], fill?: string) {
        const svg = d3.select(this.svg).append("path")
            .data([pathPoints])
            .attr("d", d3.line<Point>()
                .x(d => d.x)
                .y(d => d.y)
                .curve(d3.curveLinearClosed)
            )
            .attr("stroke", "black")
            .attr("stroke-width", 2);
        if(fill) {
            svg.attr("style", `fill: ${fill};`);
        }
    }

    private addPathWithString(pathString: string) {
        const path = d3.select(this.svg).append("g");
        path.html(pathString);
    }

    private addText(middlePoint: Point, text: string, settings?: TextSettings) {
        const svg = d3.select(this.svg).append("text")
            .attr("x", middlePoint.x)
            .attr("y", middlePoint.y)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "middle")
            .style("font-family", "Arial")
            .text(text);
        if(settings?.fill) {
            svg.style("fill", settings.fill);
        }
        if(settings?.transformData) {
            svg.attr("transform", settings.transformData);
        }
        if(settings?.fontSize) {
            svg.style("font-size", settings.fontSize);
        }
        if(settings?.fontWeight) {
            svg.style("font-weight", settings.fontWeight);
        }
    }

    private addDims(dims: Dim[]) {
        dims.forEach((dim) => {
            if (dim.type === "mullion") return;
            dim.lines.forEach((line) => {
                const seg = {
                    p1: {
                        x: line.ps.x,
                        y: line.ps.y,
                    },
                    p2: {
                        x: line.pe.x,
                        y: line.pe.y,
                    }
                };
                this.addLine(seg, false, "blue", 3);
            });
            const mainLine = dim.lines[2];
            const textCenterPoint = {
                x: (mainLine.ps.x + mainLine.pe.x)/2 + (dim.dir.y !== 0? -40 : 0),
                y: (mainLine.ps.y + mainLine.pe.y)/2 + (dim.dir.y !== 0? 0 : 40),
            };
            const textSettings: TextSettings = {
                transformData: 
                    dim.dir.y !== 0?
                        `rotate(-90, ${textCenterPoint.x}, ${textCenterPoint.y})`
                        : "",
                fontSize: this.dimFontSize,
                fontWeight: "bold",
                fill: "blue"
            };

            this.addText(textCenterPoint, dim.dir.y !== 0 ? "{H}" : "{L}", textSettings);
        });
    }

    private addTotalDims() {
        const dims = [];
        let heightDim: Dim | undefined = undefined;
        let widthDim: Dim | undefined = undefined;
        if(this.dimTotalHeight) {
            heightDim = {
                lines: this.dimTotalHeight.shapes[0],
                value: this.dimTotalHeight.value,
                dir: {
                    x: 0,
                    y: 1,
                }
            } as Dim;
        }
        if(this.dimTotalWidth) {
            widthDim = {
                lines: this.dimTotalWidth.shapes[0],
                value: this.dimTotalWidth.value,
                dir: {
                    x: 1,
                    y: 0,
                }
            } as Dim;
        }
        if(heightDim) {
            dims.push(heightDim);
        }
        if(widthDim) {
            dims.push(widthDim);
        }
        this.addDims(dims);
    }

    private drawBar(c: Child) {
        const edgePoints = c.polygon.polygon.vertices.map((v) => ({ x: v.x, y: v.y }));
        this.addPath(edgePoints, this.barColor);
    }

    private drawGlass(c: Child) {
        const edgePoints = c.polygon.polygon.vertices.map((v) => ({ x: v.x, y: v.y }));
        this.addPath(edgePoints, this.glassColor);
        this.drawChildren(c.children);
    }

    private drawGlassHole(c: Child) {
        if(c.style === "circle") {
            this.addCircle({cx: 0, cy: 0, r: c.size/2}, c.position as Point, "white");
        } else {
            const path = c.shapes[0][0].vertices.map((v: any) => ({ x: v.x, y: v.y }));
            this.addPath(path, "white");
        }
        if(!c.diameterHidden) {
            const textData = c.shapes[1];
            if(textData)
                this.addText(textData.position as Point, textData.content, { fontSize: textData.fontSize, fill: "blue" });
        }
    }

    private drawIndicator(c: Child) {
        if(c.hasOwnProperty("shapes")) {
            c.shapes.forEach((s: any) => {
                s.poly.segments.forEach((seg: any) => {
                    this.addLine({p1: seg.ps as Point, p2: seg.pe as Point}, s.dashed, "black", 2);
                });
            });
        }
    }

    private drawNote(c: Child) {
        const text = `${c.rawText}`;
        if((text.startsWith("F") || text.startsWith("A")) || text.startsWith("S") && c.hasOwnProperty("position"))
            this.addText({x: c.position.x, y: c.position.y}, c.rawText, { fontSize: c.fontSize });
    }

    private drawDim(c: Child) {
        if(c.hasOwnProperty("diMgr")) {
            this.dimsToDraw.push(...c.diMgr.visualDims);
        } else if(c.shapes.length > 0 && c.shapes[0].length > 2) {
            this.dimsToDraw.push({
                lines: c.shapes[0],
                dir: {x: 1, y: 0},
                value: c.value,
                shapes: [[]],
            });
        }
    }

    private drawHandle(c: Child) {
        c.shapes.forEach((shape: any) => {
            this.addPathWithString(shape.polygon.svg({ fill: c.color, strokeWidth: 2 }));
        });
    }

    private drawScreen(c: Child) {
        if(!this.hasIncludedScreenImage) {
            const svg = d3.select(this.svg);
            svg.append("defs").append("pattern")
                .attr("id", "patternScreen")
                .attr("patternUnits", "userSpaceOnUse")
                .attr("width", 38)
                .attr("height", 38)
            .append("image")
                .attr("xlink:href", `${screenB64}`)
                .attr("width", 40)
                .attr("height", 40)
                .attr("x", 0)
                .attr("y", 0)
                .attr("opacity", 0.5);
            this.hasIncludedScreenImage = true;
        }
        
        const edgePoints = c.polygon.polygon.vertices.map((v) => ({ x: v.x, y: v.y }));
        this.addPath(edgePoints, "url(#patternScreen)");
        this.drawChildren(c.children);
    }

    private drawPanel(c: Child) {
        const edgePoints = c.polygon.polygon.vertices.map((v) => ({ x: v.x, y: v.y }));
        this.addPath(edgePoints, this.barColor);
        const sliceNumber = c.shapes.length - 2;

        if(c.cutStyle === "Horizontal") {
            const spaceBetweenSlices = Math.abs(edgePoints[0].y - edgePoints[1].y)/sliceNumber;
            for(let i = 1 ; i < sliceNumber ; i++) {
                const actualHeight = edgePoints[0].y + i*spaceBetweenSlices;
                const seg = { p1: { x: edgePoints[0].x, y: actualHeight }, p2: { x: edgePoints[3].x, y: actualHeight } };
                this.addLine(seg);
            }
        } else if (c.cutStyle === "Vertical") {
            const spaceBetweenSlices = Math.abs(edgePoints[0].x - edgePoints[3].x)/sliceNumber;
            for(let i = 1 ; i < sliceNumber ; i++) {
                const actualWidth = edgePoints[0].x + i*spaceBetweenSlices;
                const seg = { p1: { x: actualWidth, y: edgePoints[0].y }, p2: { x: actualWidth, y: edgePoints[1].y } };
                this.addLine(seg);
            }
        }

        this.drawChildren(c.children);
    }

    private drawStrategiesMap: { [key: string]: (arg: Child) => void } = {
        Bar: this.drawBar.bind(this),
        Glass: this.drawGlass.bind(this),
        GLassHole: this.drawGlassHole.bind(this),
        Indicator: this.drawIndicator.bind(this),
        Note: this.drawNote.bind(this),
        Dim: this.drawDim.bind(this),
        Handle: this.drawHandle.bind(this),
        Screen: this.drawScreen.bind(this),
        Panel: this.drawPanel.bind(this),
    };

    private drawChildren(children: Child[] | undefined) {
        if(!children) return;

        children.forEach((c) => {
            const drawFunc = this.drawStrategiesMap[c.type];
            if(drawFunc) {
                drawFunc(c);
                return;
            }
            this.drawChildren(c.children);
        });
    }

    private addNotes() {
        this.notes.forEach(note => {
            this.addText(note.to as Point, note.text, { fontSize: note.fontSize, fill: note.color });
            if(note.shapes.length > 1) {
                let circleIndex = 1;
                if(note.shapes.length > 2) {
                    const seg = note.shapes[1].segments[0];
                    this.addLine({ p1: seg.ps as Point, p2: seg.pe as Point }, false, note.color);
                    circleIndex = 2;
                }
                this.addCircle({ cx: 0, cy: 0, r: 15 }, note.shapes[circleIndex].edges[0].pc as Point, note.color);
            }
        });
    }

    private addFrames() {
        if(!this.svg) {
            console.error("SVG Nula ou vazia");
            return;
        }

        this.shapem.forEach((frame)=> {
            this.drawChildren(frame.children);
            this.addDims(this.dimsToDraw);
            this.dimsToDraw = [];
        });
    }

    private addCouples() {
        if(!this.svg) {
            console.error("SVG Nula ou vazia");
            return;
        }

        this.couples.forEach((couple) => {
            this.drawBar(couple);
            const { xmin, xmax, ymin, ymax } = couple.polygon.box;
            const seg = {
                p1: {
                    x: xmin + Math.abs(xmin-xmax)/2,
                    y: ymin,
                },
                p2: {
                    x: xmin + Math.abs(xmin-xmax)/2,
                    y: ymax,
                },
            };
            this.addLine(seg);
            const angleData = couple.skewText;
            this.addText(
                angleData.position as Point,
                `${angleData.content}`,
                { fill: angleData.color, fontSize: angleData.fontSize, fontWeight: "bold" }
            );
        });
    }

    public convert() {
        this.addFrames();
        this.addCouples();
        this.addTotalDims();
        this.addNotes();
        d3.select(this.svg)
            .attr("width", "100%")
            .attr("height", "100%")
            .attr("viewBox", `${this.minX-50} ${this.minY-100} ${this.maxX-this.minX+100} ${this.maxY-this.minY+200}`);
        
        return this;
    }

    public getSVGString() {
        if(!this.svg){
            console.error("SVG Nula ou vazia");
            return "";
        }

        return new XMLSerializer().serializeToString(this.svg);
    }

    public downloadSVG() {
        if(!this.svg){
            console.error("SVG Nula ou vazia");
            return;
        }
        const blob = new Blob([this.getSVGString()], { type: "image/svg+xml" });
        const FileSaver = require("file-saver");

        FileSaver.saveAs(blob, "data.svg");
    }
}