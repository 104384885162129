import * as webcc from "webcc";
import React, { forwardRef } from "react";
import { LeftTab } from "./components/LeftTab";
import { RightTab } from "./components/RightTab";
import { Sidebar } from "./components/Sidebar";
import HeaderMenu from "./components/HeaderMenu";
import { leftTabs, rightTabs } from "./data/data";
import { SidebarProvider } from "./contexts/sidebarContext";
import { SelectionObserver } from "./components/rightTabComponents/SelectionObserver";
import { DesenhadorProps } from "./data/cemweb";
import { useStartWebcc } from "./hooks/useStartWebcc";
import { DesenhadorHandlers } from "./tempera";

const eventTypes = webcc.EventType;

const eventsToSubscribe = 
[
  eventTypes.frame_settings,
  eventTypes.sash_group_settings,
  eventTypes.sash_settings,
  eventTypes.sash_bar_settings,
  eventTypes.glass_hole_settings,
  eventTypes.note_settings,
  eventTypes.filler_settings,
  eventTypes.bar_modifier,
  eventTypes.turning_detect,
  eventTypes.corner_joiner_settings,
];

const Home = forwardRef<DesenhadorHandlers, DesenhadorProps>((props, ref) => {
  useStartWebcc({eventsToSubscribe, ccRef: props.ccRef});

  return (
    <>
      <HeaderMenu ref={ref} {...props}/>
      <SidebarProvider>
        <Sidebar position="left">
          <LeftTab tabs={leftTabs}/>
        </Sidebar>
        <Sidebar position="right">
          <RightTab tabs={rightTabs}/>
        </Sidebar>
        <SelectionObserver />
      </SidebarProvider>
      <div id="webcc" style={{ height: "100vh", width: "100vw" }} />
    </>
  );
});

export default Home;
