import React from "react";
import { boxFlexCenterStyle } from "../../data/data";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import DropDownMenu from "../DropDownMenu";
import ToggleButtons from "../ToggleButtons";
import { openToward, cutTypes, foldDirection } from "../../data/selectedTabsData";

const MAX_NUMBER_OF_FOLD_SASHES = 20;

export function FoldGroupSelected({payload}: {payload: any}) {
    const handleDropDownChangeCutType = (selectedValue: string) => {
        const numberValue = parseInt(selectedValue);
        if(!isNaN(numberValue)) {
            payload.jointWay = numberValue;
        }
    };

    const handleChangeOpenDirection = (selectedValue: string) => {
        payload.openToward = selectedValue;
    };

    const handleChangeDirection = (selectedValue: string) => {
        payload.horizontally = selectedValue === "horizontal"? true: false;
    };

    const handleFoldWayBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const splitted = value.split("+");
        if(splitted.length !== 2) return;

        if(splitted.some((value) => {
            return isNaN(parseInt(value));
        })) return;

        if(splitted.some((value) => {
            const v = parseInt(value);
            return v > MAX_NUMBER_OF_FOLD_SASHES || v < 1;
        })) return;

        payload.foldWay = value;
    };

    return (
        <Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Tipo de Corte</Typography>
                <DropDownMenu 
                    items={cutTypes}
                    onChange={handleDropDownChangeCutType}
                    startValue={payload.jointWay as string}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography style={{ flex: 1 }}>Abre para:</Typography>
                <ToggleButtons
                    defaultValue={payload.openToward}
                    items={openToward}
                    onChange={handleChangeOpenDirection}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography style={{ flex: 1 }}>Direção:</Typography>
                <ToggleButtons
                    defaultValue={payload.horizontally? "horizontal": "vertical"}
                    items={foldDirection}
                    onChange={handleChangeDirection}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <TextField
                    variant="outlined" size="small"
                    label="Configuração folhas"
                    onBlur={handleFoldWayBlur}
                    defaultValue={payload.foldWay}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">Ex: 3+1</InputAdornment>,
                    }}
                />
            </Box>
        </Box>
    );
}