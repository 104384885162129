import { HexAlphaColorPicker } from "react-colorful";
import { Box, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";
import React from "react";

export function ColorTab({color, setColor}: {color: string, setColor: Function}) {
    const [textFieldColor, setTextFieldColor] = useState(color);
    const handleChange = (newColor: string) => {
      setTextFieldColor(newColor);
      setColor(newColor);
    };

    const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
      const regexFull = /^#[0-9A-Fa-f]{6}$/;
      const regexPartial = /^#[0-9A-Fa-f]*$/;
      const value = event.target.value;
      if(!value) setTextFieldColor("#");
      if(value.length > 7 || !regexPartial.test(value)) return;

      setTextFieldColor("#" + value.substring(1));

      if(value.length < 7 || !regexFull.test(value)) return;
      
      handleChange(value);
    };
  
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <TextField
          sx={{ marginBottom: "10px" }}
          label="Cor Atual"
          value={textFieldColor}
          onChange={handleTextFieldChange}
          variant="outlined"
          size="small"/>
        <HexAlphaColorPicker color={color} onChange={handleChange} />
      </Box>
    );
}
