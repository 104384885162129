import React from "react";
import { Stack, Button, Tooltip } from "@mui/material";
import {
    AppsOutlined,
    CropSquare,
    WidthWide,
    AspectRatio,
    CheckBox
} from "@mui/icons-material";
import { SidebarContext } from "../contexts/sidebarContext";
import { useContext } from "react";
import { WebccContext } from "../contexts/WebccContext";

const closedLeftSidebarData = [
    { tooltip: "ESQUADRIAS", icon: AppsOutlined, index: 0 },
    { tooltip: "VIDRO TEMPERADO", icon: CropSquare, index: 1 },
];

const closedRightSidebarData = [
    { tooltip: "CONFIGURAÇÕES PERFIS", icon: WidthWide, index: 0 },
    { tooltip: "CONFIGURAÇÕES VIDROS", icon: AspectRatio, index: 1 },
    { tooltip: "CONFIGURAÇÕES SELECIONADO", icon: CheckBox, index: 2 },
];

export function ClosedSidebarIcons({position}: {position: "left" | "right"}) {
    const { cc } = useContext(WebccContext);
    const {toggleIsLeftOpen, toggleIsRightOpen, setLeftIndex, setRightIndex} = useContext(SidebarContext);
    const toggleOpen = (index: number) => {
        if(!cc) return;
        
        if(position === "left") {
            toggleIsLeftOpen();
            setLeftIndex(index);
        } else {
            toggleIsRightOpen();
            setRightIndex(index);
        }
    };
    const closedSidebarData = position === "left"? closedLeftSidebarData : closedRightSidebarData;

  return (
    <Stack mt={4} direction="column" alignItems="center" width="100%">
        {
            closedSidebarData.map((tab) => (
                <Tooltip key={tab.tooltip} title={tab.tooltip}
                    placement={position === "left"? "right" : "left"}
                    disableInteractive
                >
                    <Button onClick={() => toggleOpen(tab.index)}>
                        <tab.icon sx={{ fontSize: 32 }}/>
                    </Button>
                </Tooltip>
            ))
        }
    </Stack>
  );
}