import { MutableRefObject, useContext, useEffect, useRef } from "react";
import { WebccContext } from "../contexts/WebccContext";
import { webccTheme } from "../styles/theme";
import { WebccRuntime } from "../data/interfaces/WebccInterfaces/WebccRuntime";
import { EventData } from "../data/interfaces/eventData";
import { Frame } from "../data/interfaces/WebccInterfaces/frame";

interface UseWebccProps {
    ccRef?: MutableRefObject<any>;
    eventsToSubscribe?: string[];
}

const specialSashList = [
    "oscilobatente",
    "pivotante",
    "fixo",
];

export function useStartWebcc({ ccRef, eventsToSubscribe }: UseWebccProps = {}) {
    const { cc, setWebcc, selectedTool, setSelectedTool, setEvent, handleIsWebccLoaded } = useContext(WebccContext);
    const selectedToolRef = useRef(selectedTool);

    useEffect(() => {
      selectedToolRef.current = selectedTool;
    }, [selectedTool]);

    useEffect(() => {
        setWebcc();
    }, []);

    const findLastSash = (frame: Frame) => {
        const sashes = frame.sashManager._ifenes;
        if (!sashes) return undefined;
      
        const lastSash = sashes.reduce((prev: any, current: any) => (prev.id < current.id ? current : prev), sashes[0]);
        return lastSash;
      };
      
      const handleTurningDetect = (event: EventData, cc: WebccRuntime) => {
        setSelectedTool("pan");
        cc.toolManager.takeTool("pan");
        const lastSash = findLastSash(event.payload.frame);
        lastSash.emitSashSetting(lastSash, cc);
      };
      
      const subscribeEvent = (event: EventData) => {
        switch (event.type) {
          case "turning_detect":
            if (!specialSashList.includes(selectedToolRef.current)) return;
            handleTurningDetect(event, cc);
            break;
          case "sash_settings":
            if (selectedToolRef.current === "oscilobatente") {
              event.payload.openDirection = "left_with_down";
            } else if(selectedToolRef.current === "pivotante") {
              event.payload.openDirection = "Left_Rotate";
            } else if(selectedToolRef.current === "fixo"){
              event.payload.openDirection = "none";
              event.payload.handleHidden = "true";
            } else {
              setEvent(event);
            }
            break;
          default:
            setEvent(event);
        }
      };

    useEffect(() => {
        if(cc) {
            cc.shapeManager.dimFontSize = webccTheme.dimFontSize;
            cc.shapeManager.barNormal = webccTheme.startProfileWebccColor;
            cc.shapeManager.fixedBeadShown = false;
            cc.shapeManager.sashBeadShown = false;
            cc.shapeManager.glassColor = webccTheme.startGlassWebccColor;
            cc.shapeManager.profileSize = { frameMullion: cc.shapeManager.profileSize.sashMullion };
            cc.langMode = "en-US";
            if(eventsToSubscribe) {
                eventsToSubscribe.forEach((event) => {
                    cc.eventBus.select(event).subscribe(subscribeEvent);
                })
            }
            if(ccRef) {
                ccRef.current = cc;
            }
            handleIsWebccLoaded(true);
        }
    }, [cc]);

    return { cc };
}