import React from "react";
import { Box, Button, Typography } from "@mui/material";

export function SashBarSelected({payload}: {payload: any}) {
    const handleClick = () => {
        payload.isHandleEdge = true;
    };

    return (
        <>
            {!payload.isSlide &&
                <Box display="flex" flexDirection="column" marginTop="15px"
                    justifyContent="center" alignItems="center"
                >
                    <Typography variant="subtitle1" marginBottom="15px">Sash Bar</Typography>
                    <Button variant="outlined" onClick={handleClick}>Tornar local do puxador</Button>
                </Box>
            }
        </>
    );
}