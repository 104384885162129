import { Box, TextField, InputAdornment } from "@mui/material";
import { handleWidthRange } from "./ProfileTab";
import React from "react";

export function handleAngleRange(value: number) {
    return value <= 0 || value > 270 ? 90 : value;
}

export function CornerJoinerSelected({payload}: {payload: any}) {
    const handleWidthBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const numberValue = parseInt(event.target.value);
        if(!isNaN(numberValue)) {
            const newWidth = handleWidthRange(numberValue);
            payload.size = newWidth;
            event.target.value = String(newWidth);
        }
    };

    const handleAngleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const numberValue = parseInt(event.target.value);
        if(!isNaN(numberValue)) {
            const newAngle = handleAngleRange(numberValue);
            payload.angle = newAngle;
            event.target.value = String(newAngle);
        }
    };

    return (
        <Box>
            <Box marginTop="15px" display="flex" padding="0px 20px">
                <TextField
                    variant="outlined" size="small"
                    label="Largura do conector"
                    onBlur={handleWidthBlur}
                    defaultValue={String(payload.size)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    }}
                />
            </Box>
            <Box marginTop="15px" display="flex" padding="0px 20px">
                <TextField
                    variant="outlined" size="small"
                    label="Ângulo"
                    onBlur={handleAngleBlur}
                    defaultValue={String(payload.angle)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">graus</InputAdornment>,
                    }}
                />
            </Box>
        </Box>
    );
}