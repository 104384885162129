import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState, useEffect } from "react";

interface Item {
    label: string;
    value: string;
}

interface ToggleButtonsProps {
    items: Item[];
    defaultValue: string;
    onChange?: (selectedValue: string) => void;
}

export default function ToggleButtons(props: ToggleButtonsProps) {
  const [value, setValue] = useState(props.defaultValue);
  
  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);
  
  const handleChange = (newValue: string | null) => {
    if(newValue) {
      setValue(newValue);
      if(props.onChange) {
          props.onChange(newValue);
      }
    }
  };

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      size="small"
      onChange={(_event, newValue) => handleChange(newValue)}
    >
        {props.items.map((item) => (
            <ToggleButton key={item.label}
                value={item.value}>{item.label}
            </ToggleButton>
        ))}
    </ToggleButtonGroup>
  );
}
