import React from "react";
import { Box, Typography, TextField, InputAdornment } from "@mui/material";
import DropDownMenu from "../DropDownMenu";
import { useContext } from "react";
import { WebccContext } from "../../contexts/WebccContext";
import { MAX_PROFILE_WIDTH, boxFlexCenterStyle } from "../../data/data";
import { cutTypes } from "../../data/selectedTabsData";
import { CustomSwitch } from "../Switches";

export function FrameSelected({payload}: {payload: any}) {
    const { cc } = useContext(WebccContext);

    const handleDropDownChangeCutType = (selectedValue: string) => {
        const numberValue = parseInt(selectedValue);
        if(!isNaN(numberValue)) {
            payload.jointWay = numberValue;
        }
    };

    const handleTextFieldBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const numberValue = parseInt(event.target.value);
        if(!isNaN(numberValue)) {
            if(numberValue < 0) {
                payload.frame.profileSizeManager.frame = numberValue;
                event.target.value = "0";
            } else if(numberValue > MAX_PROFILE_WIDTH) {
                payload.frame.profileSizeManager.frame = MAX_PROFILE_WIDTH;
                event.target.value = String(MAX_PROFILE_WIDTH);
            } else {
                payload.frame.profileSizeManager.frame = numberValue;
                event.target.value = String(numberValue);
            }
            cc.shapeManager.updatePoly();
            cc.mometoManager.checkPoint();
        }
    };

    const handleTopViewSwitch = (selectedValue: boolean) => {
        payload.topViewHidden = !selectedValue;
    };
    
    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop="15px">
            <Box style={boxFlexCenterStyle}>
                <TextField
                    variant="outlined" size="small"
                    label="Largura do Perfil"
                    onBlur={handleTextFieldBlur}
                    defaultValue={String(payload.frame.profileSizeManager.frame)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    }}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Tipo de Corte</Typography>
                <DropDownMenu 
                    items={cutTypes}
                    onChange={handleDropDownChangeCutType}
                    startValue={payload.jointWay as string}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Mostrar<br/>Vista superior</Typography>
                <CustomSwitch
                    startValue={!payload.topViewHidden}
                    onChange={handleTopViewSwitch}
                />
            </Box>
        </Box>
    );
}