import React from "react";
import { theme } from "../styles/theme";
import { Stack, StackProps, css } from "@mui/material";
import { ReactNode } from "react";

export const commonScrollbar = css`
	::-webkit-scrollbar {
		width: 8px;
		border-radius: 8px;
	}

	::-webkit-scrollbar-track {
		background: ${theme.palette.secondary[200]};
		border-radius: 8px;
	}

	::-webkit-scrollbar-thumb {
		background: ${theme.palette.primary[100]};
		height: 40px;
		border-radius: 8px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: ${theme.palette.primary[200]};
	}
`;

interface StackScrollProps extends StackProps {
  children: ReactNode;
}

export function StackScroll({children, ...props}: StackScrollProps) {

  return (
    <Stack sx={{
      overflow: "auto",
      ...commonScrollbar,
      ...props.sx,
    }} {...props}>
      {children}
    </Stack>
  );
}