import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { WebccContext } from "../contexts/WebccContext";
import { theme } from "../styles/theme";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useContext } from "react";
import { DropdownButtons } from "./DropDownButtons";
import { SVGConverter } from "../utils/exportSvgClass";
import { ButtonData } from "../data/interfaces/buttonData";
import { DesenhadorProps } from "../data/cemweb";
import { MaximarHandle } from "./MaximarHandler";
import { DesenhadorHandlers } from "../tempera";

function SmallScreenHeaderMenu({buttonsData, buttonsDropDownData}: {buttonsData: ButtonData[], buttonsDropDownData: ButtonData[]}) {
  const { cc } = useContext(WebccContext);

  for(let i=buttonsData.length-1;i>2;i--) {
    buttonsDropDownData.unshift(buttonsData[i]);
    buttonsData.pop();
  }

  return (
    <Box sx={{ position: "fixed", zIndex: theme.zIndex.drawer + 2, width: "100vw", backgroundColor: theme.palette.primary.main, padding: "2.5px 15px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" style={{ color: theme.palette.primary.contrastText }}>
           
        </Typography>
        <Box display="flex" alignItems="center">
          {cc && buttonsData.map((button, index) => (
            <Button size="small" key={index} onClick={ button.onClick }
              variant="outlined" color="inherit"
              sx={{ color: "#fff", margin: "0px 5px" }}>{button.label}</Button>
          ))}
          {cc && <DropdownButtons buttonsData={buttonsDropDownData} label="Opções" />}
        </Box>
      </Box>
    </Box>
  );
}

const HeaderMenu = forwardRef<DesenhadorHandlers, DesenhadorProps>((props, ref) => {
  const { cc, setEvent, isWebccLoaded } = useContext(WebccContext);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const openJSON = (config: string) => {
    cc.shapeManager.openFile(config, false);
    const configObj = JSON.parse(config);
    if(configObj.sm[0]) {
      const colorManager = configObj.sm[0].cm;
      cc.shapeManager.barNormal = colorManager.bn;
      cc.shapeManager.glassColor = colorManager.g;
    }
  };

  useEffect(() => {
    if(cc && props.loadJSON && isWebccLoaded) {
      openJSON(props.loadJSON);
    }
  }, [cc, props.loadJSON, isWebccLoaded]);

  useEffect(() => {
    if(cc && props.variables) {
      const frame = cc.shapeManager.shapem[0];
      if(frame) {
        try{
          frame.resize(props.variables.L, props.variables.H);
          frame.updatePoly();
          frame.draw(cc);
          cc.mometoManager.checkPoint();
          sendToCEMWEB();
        } catch (error) {
          console.error("Erro ao mudar Largura ou Altura", error);
          cc.shapeManager.clear();
          setEvent({ type: "" });
          if(props.retrieveDrawData)
            props.retrieveDrawData({ JSON: "", svg: "", png64: "" });
        }
      }
    }
  }, [cc, props.variables])

  useEffect(() => {
    if(cc && props.colors && isWebccLoaded) {
      if(props.colors.perfil)
        cc.shapeManager.barNormal = props.colors.perfil ?? cc.shapeManager.barNormal;
      if(props.colors.vidro)
        cc.shapeManager.glassColor = props.colors.vidro ?? cc.shapeManager.glassColor;
      sendToCEMWEB();
    }
  }, [cc, props.colors, isWebccLoaded]);
  
  const importFunction = () => {
    const input: HTMLInputElement = document.createElement("input");
    input.type = "file";
    input.accept = "application/json";
    input.onchange = (event: Event) => {
      const files = (event.target as HTMLInputElement).files;
      if (files && files.length > 0) {
        const reader = new FileReader();
        reader.onload = (event: Event) => {
          const config = String((event.target as FileReader).result);
          openJSON(config);
        };
        reader.readAsText(files[0]);
      }
    };
    setEvent({ type: "" });
    input.click();
  };

  const sendToCEMWEB = () => {
    if(cc.shapeManager.shapem.length === 0) return {JSON: "", svg: "", png64: ""};
    
    const drawDataJSON = cc.shapeManager.serialize();
    const drawDataPNGb64 = cc.shapeManager.toData();
      
    const svgConverter = new SVGConverter(cc);
    const drawDataSVG = svgConverter.convert().getSVGString();

    if(props.retrieveDrawData) {
      props.retrieveDrawData({JSON: drawDataJSON, svg: drawDataSVG, png64: drawDataPNGb64}, "header");
    }

    return {JSON: drawDataJSON, svg: drawDataSVG, png64: drawDataPNGb64};
  };

  const buttonsData = [
    { label: "Desfazer", onClick: () => { cc.mometoManager.undo(); setEvent({ type: "" });} },
    { label: "Refazer", onClick: () => { cc.mometoManager.redo(); setEvent({ type: "" });} },
    { label: "Apagar", onClick: () => { cc.shapeManager.remove(); setEvent({ type: "" });} },
    { label: "Limpar", onClick: () => { cc.shapeManager.clear(); setEvent({ type: "" });} },
    { label: "Alinhar", onClick: () => cc.shapeManager.moveShapeToCenter() },
    { label: "Importar", onClick: importFunction },
    { label: "Salvar", onClick: () =>{
      sendToCEMWEB();
      if(props.onSave) {
        props.onSave();
      }
    }}
  ];

  const alignDesenhador = () => {
    cc.shapeManager.moveShapeToCenter();
  }

  useImperativeHandle(ref, () => ({
    sendToCEMWEB,
    alignDesenhador,
  }));

  return (
    <>
      { isSmallScreen? <SmallScreenHeaderMenu buttonsData={buttonsData} buttonsDropDownData={[]} /> : 
        <Box sx={{ position: "fixed", zIndex: theme.zIndex.drawer + 2, width: "100vw", backgroundColor: "primary.main", padding: "2.5px 15px" }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography 
              variant="h6" 
              style={{ color: theme.palette.primary.contrastText }}
              marginLeft="90px"
            >
              Desenhador
            </Typography>
            <Box display="flex" alignItems="center">
              {cc && buttonsData.map((button, index) => (
                <Button size="small" key={index} onClick={ button.onClick }
                  variant="outlined" color="inherit"
                  sx={{ color: "#fff", margin: "0px 5px" }}>{button.label}</Button>
              ))}
            </Box>
          </Box>
        </Box>
      }
      {props.maximarData && props.retrieveDrawData && 
        <MaximarHandle {...props.maximarData} retrieveDrawData={props.retrieveDrawData}/>}
    </>
  );
});

export default HeaderMenu;