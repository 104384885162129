import { WebccContext } from "../../contexts/WebccContext";
import { SidebarContext } from "../../contexts/sidebarContext";
import { useContext, useEffect } from "react";

export function SelectionObserver() {
    const { isRightOpen, toggleIsRightOpen, setRightIndex } = useContext(SidebarContext);
    const { eventData } = useContext(WebccContext);

    useEffect(() => {
        if(eventData.type !== "") {
            if(!isRightOpen) {
                toggleIsRightOpen();
            }
            setRightIndex(2);
        }
    }, [eventData.payload]);
    
    return null;
}