import React from "react";
import { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";

interface SwitchProps {
  startValue: boolean;
  onChange?: (selectedValue: boolean) => void;
}

export function CustomSwitch({ startValue, onChange }: SwitchProps) {
  const [switchState, setSwitchState] = useState(startValue);

  useEffect(() => {
    setSwitchState(startValue);
  }, [startValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchState(event.target.checked);

    if(onChange) {
      onChange(event.target.checked);
    }
  };

  return (
    <Switch
      checked={switchState}
      onChange={handleChange}
    />
  );
};