import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { ReactNode, useContext } from "react";
import Expander from "../components/Expander";
import { LeftTabData } from "../data/interfaces/leftTabData";
import { SidebarContext } from "../contexts/sidebarContext";
import { WebccContext } from "../contexts/WebccContext";

interface PanelProps {
  children: ReactNode,
  value: number,
  index: number,
}

function TabPanel(props: PanelProps) {
  return (
    <Box>
      {
        props.value === props.index && (
          props.children
        )
      }
    </Box>
  );
}

interface CustomTabProps {
  tabs: LeftTabData[];
}

export function LeftTab(props: CustomTabProps) {
  const { cc } = useContext(WebccContext);
  const {leftTabIndex, setLeftIndex} = useContext(SidebarContext);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setLeftIndex(newValue);
    if(newValue === 1) {
      cc.shapeManager.profileSize = {
        frame: 5,
        sash: 5,
        screen: 5,
        downSash: 5,
        shadeSash: 5,
        upSash: 5,
        interlock: 5,
        sashMullion: 5,
        frameMullion: 5,
      };
    }
  };
  
  return (
    <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Tabs value={leftTabIndex} onChange={handleChange}>
        {props.tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
        
      </Tabs>
      
      {props.tabs.map((tab, i) => (
        <TabPanel key={i} value={leftTabIndex} index={i}>
          {tab.accordions.map((accordion, j) => (
            <Expander key={j} name={accordion.name} content={accordion.content}/>
          ))}
        </TabPanel>
      ))}
    </Box>
  );
}