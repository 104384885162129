import React from "react";
import ThemeRegistry from "./ThemeRegistry";
import { WebccProvider } from "./contexts/WebccContext";

interface Props {
  children: React.ReactNode
}

export default function App({children}: Props) {
  return (
    <>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap"
        rel="stylesheet" />

      <ThemeRegistry options={{ key: "mui" }}>
        <WebccProvider>
          {children}
        </WebccProvider>
      </ThemeRegistry>
    </>
  );
}