import React from "react";
import { Box, Button } from "@mui/material";
import { ColorTab } from "./ColorTab";
import { useContext, useState } from "react";
import { WebccContext } from "../../contexts/WebccContext";
import { webccTheme } from "../../styles/theme";

export function GlassTab() {
    const { cc } = useContext(WebccContext);
    const [color, setColor] = useState(cc.shapeManager.glassColor);

    const handleDefaultClick = () => {
        cc.shapeManager.glassColor = webccTheme.startGlassWebccColor;
        setColor(webccTheme.startGlassWebccColor);
    };

    const handleApplyClick = () => {
        cc.shapeManager.glassColor = color;
    };

    return(
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop="15px">
            <ColorTab color={color} setColor={setColor}></ColorTab>
            <Box display="flex" flexDirection="column">
                <Button sx={{ marginTop: "10px" }} onClick={handleDefaultClick}>Reverter para cor padrão</Button>
                <Button onClick={handleApplyClick} variant="outlined">Aplicar</Button>
            </Box>
        </Box>
    );
}