import React from "react";
import { WebccContext } from "../../contexts/WebccContext";
import { Box, Button, TextField, InputAdornment } from "@mui/material";
import { useContext, useState } from "react";
import { ColorTab } from "./ColorTab";
import { webccTheme } from "../../styles/theme";
import { MAX_PROFILE_WIDTH } from "../../data/data";
import { DEFAULT_PROFILE_WIDTH } from "../../data/selectedTabsData";

export function handleWidthRange(value: number) {
    if(value <= 0) {
        return DEFAULT_PROFILE_WIDTH;
    } else if(value > MAX_PROFILE_WIDTH) {
        return MAX_PROFILE_WIDTH;
    } else {
        return value;
    }
}
 
export function ProfileTab() {
    const { cc } = useContext(WebccContext);
    const [color, setColor] = useState(cc.shapeManager.barNormal);

    const handleFrameTextFieldBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const numberValue = parseInt(event.target.value);
        if(!isNaN(numberValue)) {
            const newFrameWidth = handleWidthRange(numberValue);
            cc.shapeManager.profileSize = { frame: newFrameWidth };
            event.target.value = String(newFrameWidth);
        }
    };

    const handleSashTextFieldBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const numberValue = parseInt(event.target.value);
        if(!isNaN(numberValue)) {
            const newSashWidth = handleWidthRange(numberValue);
            cc.shapeManager.profileSize = {
                sash: newSashWidth,
                screen: newSashWidth,
                downSash: newSashWidth,
                shadeSash: newSashWidth,
                upSash: newSashWidth,
                interlock: newSashWidth,
            };
            event.target.value = String(newSashWidth);
        }
    };

    const handleMullionTextFieldBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const numberValue = parseInt(event.target.value);
        if(!isNaN(numberValue)) {
            const newMullionWidth = handleWidthRange(numberValue);
            cc.shapeManager.profileSize = {
                sashMullion: newMullionWidth,
                frameMullion: newMullionWidth,
            };
            event.target.value = String(newMullionWidth);
        }
    };

    const handleApplyClick = () => {
        cc.shapeManager.barNormal = color;
    };

    const handleDefaultClick = () => {
        cc.shapeManager.barNormal = webccTheme.startProfileWebccColor;
        setColor(webccTheme.startProfileWebccColor);
    };

    return(
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Box marginTop="15px" display="flex" padding="0px 20px">
                <TextField
                    variant="outlined" size="small"
                    label="Largura dos Frames"
                    onBlur={handleFrameTextFieldBlur}
                    defaultValue={String(cc.shapeManager.profileSize.frame)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    }}
                />
            </Box>
            <Box marginTop="15px" display="flex" padding="0px 20px">
                <TextField
                    variant="outlined" size="small"
                    label="Largura das Folhas"
                    onBlur={handleSashTextFieldBlur}
                    defaultValue={String(cc.shapeManager.profileSize.sash)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    }}
                />
            </Box>
            <Box margin="15px 0px" display="flex" padding="0px 20px">
                <TextField
                    variant="outlined" size="small"
                    label="Largura dos Montantes/Travessas"
                    onBlur={handleMullionTextFieldBlur}
                    defaultValue={String(cc.shapeManager.profileSize.frameMullion)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    }}
                />
            </Box>
            <ColorTab color={color} setColor={setColor}></ColorTab>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Button sx={{ marginTop: "10px" }} onClick={handleDefaultClick}>Reverter para cor padrão</Button>
                <Button onClick={handleApplyClick} variant="outlined">Aplicar</Button>
            </Box>
        </Box>
    );
}