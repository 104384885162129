import React from "react";
import { Box, Typography } from "@mui/material";
import ToggleButtons from "../ToggleButtons";
import { boxFlexCenterStyle } from "../../data/data";
import { CustomSwitch } from "../Switches";

const holeStyles = [
    { label: "Círculo", value: "circle" },
    { label: "Retangulo", value: "square" }
];

export function GlassHoleSelected({payload}: {payload: any}) {
    const handleDiameterChange = (selectedValue: boolean) => {
        payload.diameterHidden = !selectedValue;
    };
    const handleVerticalChange = (selectedValue: boolean) => {
        payload.vMarginDimHidden = !selectedValue;
    };
    
    const handleHorizontalChange = (selectedValue: boolean) => {
        payload.hMarginDimHidden = !selectedValue;
    };

    const handleHoleChange = (selectedValue: string) => {
        payload.style = selectedValue;
    };

    return (
        <Box display="flex" flexDirection="column" marginTop="15px">
            <Box style={boxFlexCenterStyle}>
                <Typography style={{ flex: 1 }}>Tipo<br/>do furo</Typography>
                <ToggleButtons defaultValue={String(payload.style)}
                    items={holeStyles}
                    onChange={handleHoleChange}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Mostrar<br/>Tamanho</Typography>
                <CustomSwitch
                    startValue={!payload.diameterHidden}
                    onChange={handleDiameterChange}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Mostrar<br/>Cota Vertical</Typography>
                <CustomSwitch
                    startValue={!payload.vMarginDimHidden}
                    onChange={handleVerticalChange}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Mostrar<br/>Cota Horizontal</Typography>
                <CustomSwitch
                    startValue={!payload.hMarginDimHidden}
                    onChange={handleHorizontalChange}
                />
            </Box>
        </Box>        
    );
}