import React from "react";
import { useContext } from "react";
import { FrameSelected } from "./FrameSelected";
import { SashSelected } from "./SashSelected";
import { SashBarSelected } from "./SashBarSelected";
import { SashGroupSelected } from "./SashGroupSelected";
import { WebccContext } from "../../contexts/WebccContext";
import { SlideGroupSelected } from "./SlideGroupSelected";
import { SlideSelected } from "./SlideSelected";
import { GlassHoleSelected } from "./GlassHoleSelected";
import { NoteSelected } from "./NoteSelected";
import { FillerSelected } from "./FIllerSelected";
import { MullionSelected } from "./MullionSelected";
import { FoldGroupSelected } from "./FoldGroupSelected";
import { EventData } from "../../data/interfaces/eventData";
import { Stack } from "@mui/material";
import { CornerJoinerSelected } from "./CornerJoinerSelected";
import { AntiTheftSelected } from "./AntiTheftSelected";

interface ComponentMap {
    [key: string]: React.ComponentType<{ payload: any }>;
}

const componentMap: ComponentMap = {
    frame_settings: FrameSelected,
    sash_settings: SashSelected,
    sash_group_settings: SashGroupSelected,
    sash_bar_settings: SashBarSelected,
    slide_group_settings: SlideGroupSelected,
    slide_settings: SlideSelected,
    glass_hole_settings: GlassHoleSelected,
    note_settings: NoteSelected,
    filler_settings: FillerSelected,
    bar_modifier: MullionSelected,
    fold_group_settings: FoldGroupSelected,
    corner_joiner_settings: CornerJoinerSelected,
    anti_theft_settings: AntiTheftSelected,
};

interface TypeMap {
    [key: string]: string;
}
const typeMap: TypeMap = {
    DoubleSash: "sash_group_settings",
    Sash: "sash_settings",
    Slide: "slide_group_settings",
    Screen: "slide_settings",
    FoldSash: "fold_group_settings",
    AntiTheft: "anti_theft_settings"
};

export function SelectedTab() {
    const { eventData } = useContext(WebccContext);
    let payload = eventData.payload;
    let type = eventData.type;

    const eventArray: EventData[] = [];

    if(type === "sash_group_settings") {
        if(payload.settings.length === 1 && payload.settings[0].target.parent === "Frame") {
            eventArray.push({
                type: "sash_settings",
                payload: payload.settings[0],
            });
        } else {
            payload.settings.forEach((setting: any) => {
                eventArray.push({
                    type: setting.isSlide? "slide_settings" : typeMap[setting.target.type],
                    payload: setting,
                });
            });
        }
    } 
    
    if(type === "sash_settings") {
        if(payload.isSlide) {
            eventArray.push({
                type: "slide_settings",
                payload,
            });
        }
    }

    if(eventArray.length === 0 && type !== "") {
        eventArray.push({
            type,
            payload,
        });
    }

    const SelectedComponents: [React.ComponentType<{ payload: any }>, any][] = [];
    eventArray.forEach(event => {
        const MappedComponent = componentMap[event.type];
        SelectedComponents.push([MappedComponent, event.payload]);
    });

    return (
        <Stack direction="column" spacing="20px">
            {SelectedComponents.map(([Component, payload], index) => (
                <Component key={index} payload={payload} />
            ))}
        </Stack>
    );
}