import { useContext, useEffect, useState } from 'react';
import { openJSON } from '../utils/webccUtils';
import { WebccContext } from '../contexts/WebccContext';
import { ExportData } from '../data/cemweb';
import { SVGConverter } from '../utils/exportSvgClass';

interface MaximarHandlerProps {
  id: string;
  numberModules: number;
  retrieveDrawData: (data: ExportData, type?: string) => void;
}

export function MaximarHandle({ id, numberModules, retrieveDrawData }: MaximarHandlerProps) {
  const [maximarData, setMaximarData] = useState<string[]>();
  const { cc } = useContext(WebccContext);

  const sendToCEMWEB = () => {
    if(cc.shapeManager.shapem.length === 0) return;
    
    if(retrieveDrawData) {
      const drawDataJSON = cc.shapeManager.serialize();
      const drawDataPNGb64 = cc.shapeManager.toData();
      
      const svgConverter = new SVGConverter(cc);
      const drawDataSVG = svgConverter.convert().getSVGString();
      retrieveDrawData({JSON: drawDataJSON, svg: drawDataSVG, png64: drawDataPNGb64}, "maximar");
    }
  };

  useEffect(() => {
    async function importMaximarData() {
      if (id && numberModules) {
        const maximarArray: string[] = (await import(`../src/data/maximarData/${id}.ts`))
          .default;
        setMaximarData(maximarArray);
      }
    }
    importMaximarData();
  }, [id]);

  useEffect(() => {
    if (cc && id && numberModules && maximarData) {
      try {
        const MAX_MODULES = 5;
        const idx = Math.max(1, Math.min(MAX_MODULES, numberModules));
        const colors = {
          perfil: `${cc.shapeManager.barNormal}`,
          vidro: `${cc.shapeManager.glassColor}`,
        };
        openJSON(cc, maximarData[idx]);
        cc.shapeManager.barNormal = colors.perfil;
        cc.shapeManager.vidro = colors.vidro;
        sendToCEMWEB();
      } catch (error) {
        console.error("Erro ao mudar as configurações do maximar", error);
      }
    }
  }, [cc?.shapeManager.shapem, numberModules, maximarData]);

  return null;
}
