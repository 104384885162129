import { MAX_PROFILE_WIDTH, boxFlexCenterStyle } from "../../data/data";
import { DEFAULT_MULLION_WIDTH, tiposEqualizacao } from "../../data/selectedTabsData";
import { Box, TextField, InputAdornment, Typography } from "@mui/material";
import { CustomSwitch } from "../Switches";
import DropDownMenu from "../DropDownMenu";
import { useState } from "react";
import React from "react";

export function MullionSelected({payload}: {payload: any}) {
    const [showTipoEqualizacao, setShowTipoEqualizacao] = useState<boolean>(payload.equalSplit);

    const handleTextFieldBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const numberValue = parseInt(event.target.value);
        if(!isNaN(numberValue)) {
            if(numberValue <= 0) {
                payload.profileSize = DEFAULT_MULLION_WIDTH;
                event.target.value = String(DEFAULT_MULLION_WIDTH);
            } else if(numberValue > MAX_PROFILE_WIDTH) {
                payload.profileSize = MAX_PROFILE_WIDTH;
                event.target.value = String(MAX_PROFILE_WIDTH);
            } else {
                payload.profileSize = numberValue;
                event.target.value = String(numberValue);
            }
        }
    };

    const handleToggleEqualSplit = (selectedValue: boolean) => {
        payload.equalSplit = selectedValue;
        setShowTipoEqualizacao(selectedValue);
    }

    const handleChangeSplitSide = (selectedValue: string) => {
        const numberValue = parseInt(selectedValue);
        if(!isNaN(numberValue)) {
            payload.equalSplitSide = numberValue;
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop="15px">
            <Typography variant="subtitle1">Travessa/Montante</Typography>
            <Box style={boxFlexCenterStyle}>
                <TextField
                    variant="outlined" size="small"
                    defaultValue={String(payload.profileSize)}
                    label="Largura"
                    onBlur={handleTextFieldBlur}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    }}
                />
            </Box>
            <Box style={boxFlexCenterStyle}>
                <Typography>Equalizar</Typography>
                <CustomSwitch
                    startValue={payload.equalSplit}
                    onChange={handleToggleEqualSplit}
                />
            </Box>
            { showTipoEqualizacao && 
                <Box style={boxFlexCenterStyle}>
                    <Typography>Direção<br/>Equalização</Typography>
                    <DropDownMenu 
                        items={tiposEqualizacao}
                        onChange={handleChangeSplitSide}
                        startValue={payload.equalSplitSide as string}
                    />
                </Box>
            }
        </Box>
    );
}