import React from "react";
import { useState } from "react";
import { ColorTab } from "./ColorTab";
import { Box, Button, InputAdornment, TextField } from "@mui/material";

const MAX_FONT_SIZE = 300;
const MIN_FONT_SIZE = 30;

export function NoteSelected({payload}: {payload: any}) {
    const [color, setColor] = useState(payload.color === "blue"? "#00f" : payload.color);
    
    const handleTextFieldBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const numberValue = parseInt(event.target.value);
        if(!isNaN(numberValue)) {
            if(numberValue < MIN_FONT_SIZE) {
                payload.fontSize = MIN_FONT_SIZE;
                event.target.value = String(MIN_FONT_SIZE);
            } else if(numberValue > MAX_FONT_SIZE) {
                payload.fontSize = MAX_FONT_SIZE;
                event.target.value = String(MAX_FONT_SIZE);
            } else {
                payload.fontSize = numberValue;
            }
        }
    };

    const handleApplyClick = () => {
        payload.color = color;
    };
    
    return (
        <Box display="flex" flexDirection="column">
            <TextField sx={{ margin: "15px 0px" }}
                variant="outlined" size="small"
                label="Tamanho da fonte"
                onBlur={handleTextFieldBlur}
                InputProps={{
                    endAdornment: <InputAdornment position="end">px</InputAdornment>,
                }}
            />
            <ColorTab color={color} setColor={setColor}></ColorTab>
            <Button sx={{ margin: "10px 0px" }} onClick={handleApplyClick} variant="outlined">Aplicar cor</Button>
        </Box>
    );
}