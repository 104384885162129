import React from "react";
import { CSSObject, Drawer, Stack, Theme, IconButton, Box, Typography, useMediaQuery } from "@mui/material";
import { CustomDrawer } from "./CustomDrawer";
import { ClosedSidebarIcons } from "./ClosedSidebarIcons";
import { ReactNode, useContext } from "react";
import { theme } from "../styles/theme";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { SidebarContext } from "../contexts/sidebarContext";
import { WebccContext } from "../contexts/WebccContext";

const drawer_width = 310;

export type SideBarPosition = "left" | "right";

interface SidebarProps {
  children?: ReactNode;
  position?: SideBarPosition;
}

export function Sidebar({ children, position = "left" }: SidebarProps) {
  const { cc } = useContext(WebccContext);
  const {isLeftOpen, toggleIsLeftOpen, isRightOpen, toggleIsRightOpen} = useContext(SidebarContext);
  const isOpen = position === "left"? isLeftOpen : isRightOpen;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleChevronClick = () => {
    if(cc) {
      position === "left"? toggleIsLeftOpen() : toggleIsRightOpen();
    }
  };

  return (
    <Drawer variant="permanent" open={isOpen} sx={{
      width: drawer_width,
      flexShrink: 0,
      whiteSpace: "nowrap",
      boxSizing: "border-box",
      position: "relative",

      zIndex: theme.zIndex.drawer + 1,
      ...(isOpen && {
        ...openedMixin(theme, position),
        "& .MuiDrawer-paper": openedMixin(theme, position),
      }),
      ...(!isOpen && {
        ...closedMixin(theme, position, isSmallScreen),
        "& .MuiDrawer-paper": closedMixin(theme, position, isSmallScreen),
      }),
    }}>
      <CustomDrawer>
        <Box display="flex" px={2} my={1}>
          {position === "left" && isOpen?
           <Typography my={1} variant="h6" style={{
              fontWeight: "normal",
              color: theme.palette.text.primary
            }}
            >Ferramentas</Typography> : null
          }
          <IconButton sx={{
            width: 36,
            height: 36,
            marginLeft: position === "left" ? "auto" : 0,
            marginRight: position === "right" ? "auto" : 0,
          }} color="primary" onClick={handleChevronClick}>
            {position === "left" && <> {isOpen ? <ChevronLeft /> : <ChevronRight />}</>}
            {position === "right" && <> {isOpen ? <ChevronRight /> : <ChevronLeft />}</>}
          </IconButton>
          {position === "right" && isOpen?
           <Typography my={1} variant="h6" style={{
              fontWeight: "normal",
              color: theme.palette.text.primary
            }}
            >Propriedades</Typography> : null
          }
        </Box>
        {isOpen ? (
          <Stack direction="column" alignItems="center" width="100%">
            {children}
          </Stack>
        ) : !isSmallScreen && (
          <ClosedSidebarIcons position={position} />
        )}
      </CustomDrawer>

    </Drawer>
  );
}

const openedMixin = (theme: Theme, position: SideBarPosition): CSSObject => ({
  paddingTop: "37px",
  position: "absolute",
  width: drawer_width,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  left: position === "left" ? 0 : "auto",
  right: position === "right" ? 0 : "auto",
  height: "100%"
});

const closedMixin = (theme: Theme, position: SideBarPosition, isSmallScreen: boolean): CSSObject => ({
  paddingTop: "37px",
  position: "absolute",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(10.5)} + 1px)`,
  height: isSmallScreen? "90px" : "100%",
  left: position === "left" ? 0 : "auto",
  right: position === "right" ? 0 : "auto",
});