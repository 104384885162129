import React from "react";
import { WebccRuntime } from "../data/interfaces/WebccInterfaces/WebccRuntime";
import { EventData } from "../data/interfaces/eventData";
import { ReactNode, createContext, useState } from "react";
import * as webcc from "webcc";

export interface WebccContextData {
    cc: WebccRuntime;
    setWebcc: Function;
    eventData: EventData;
    setEvent: Function;
    selectedTool: string;
    setSelectedTool: (data: string) => void;
    isWebccLoaded: boolean;
    handleIsWebccLoaded: (value: boolean) => void;
}

export const WebccContext = createContext(
    {} as WebccContextData,
);

interface WebccProviderProps {
	children: ReactNode;
}

export function WebccProvider({children}: WebccProviderProps) {
    const [cc, setCc] = useState<WebccRuntime>();
    const [selectedTool, setSelectedTool] = useState("pan");
    const [eventData, setEventData] = useState<EventData>({ type: "", payload: {} });
    const [isWebccLoaded, setIsWebccLoaded] = useState(false);

    const setWebcc = () => {
      try {
        setCc(new webcc.Runtime("eyJuYW1lIjoianVsaWFubyIsInN0bSI6MTcyNTU4MDgwMDAwMCwiZXhwIjoxNzU3MjAzMjAwMDAwLCJsaXRlIjpmYWxzZX0=.885d8dd079d7610b058414eb109845fc", "webcc"));
      } catch (error) {
        console.error("Erro ao criar objeto webcc", error);
      }
    };

    const setEvent = (event: EventData) => {
      setEventData(event);
    };

    const handleIsWebccLoaded = (value: boolean) => {
      setIsWebccLoaded(value);
    }

    const valuesContext = {
        cc: cc!,
        setWebcc,
        eventData,
        setEvent,
        selectedTool,
        setSelectedTool,
        isWebccLoaded,
        handleIsWebccLoaded,
    };

    return (
    <WebccContext.Provider
        value={valuesContext}
    >
        {children}
    </WebccContext.Provider>
    );
}