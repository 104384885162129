import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material";

interface DropDownField {
    label: string,
    value: string,
}
interface DropDownMenuProps {
    items: DropDownField[];
    startValue?: string;
    onChange?: (selectedValue: string) => void;
}

export default function DropDownMenu(props: DropDownMenuProps) {
  const startValue = props.startValue? props.startValue : props.items[0].value;
  const [value, setValue] = useState(startValue);
  const theme = useTheme();

  useEffect(() => {
    setValue(startValue);
  }, [startValue]);

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);

    if(props.onChange) {
      props.onChange(event.target.value);
    }
  };

  return (
    <Box sx={{ minWidth: 150 }}>
      <FormControl fullWidth >
        <Select sx={{ height: 35, backgroundColor: theme.palette.secondary["main"] }}
          value={value}
          onChange={handleChange}
        >
          {props.items.map((item) => (
            <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
