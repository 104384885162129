import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import DropDownMenu from "../DropDownMenu";
import { panelCutStyles } from "../../data/selectedTabsData";
import { boxFlexCenterStyle } from "../../data/data";

export function FillerSelected({payload}: {payload: any}) {
    const handleTextFieldBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        payload.spec = event.target.value;
    };

    const handlePanelCutStyleChange = (selectedValue: string) => {
        payload.cutStyle = selectedValue;
    };

    if (!payload.filler) return null;

    return (
        <>
            { payload.filler.type === "Glass" &&
            <Box display="flex" flexDirection="column">
                <TextField sx={{ margin: "15px 0px" }}
                    variant="outlined" size="small"
                    label="Anotações"
                    onBlur={handleTextFieldBlur}
                    defaultValue={payload.spec}
                />
            </Box>
            }
            { payload.filler.type === "Panel" &&
                <Box style={boxFlexCenterStyle}>
                    <Typography>Tipo<br/>de Corte</Typography>
                    <DropDownMenu
                        items={panelCutStyles}
                        onChange={handlePanelCutStyleChange}
                        startValue={String(payload.cutStyle)}
                    />
                </Box>
            }
        </>
    );
}