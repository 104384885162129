import React, { forwardRef } from "react";
import App from "./App";
import Home from "./page";
import { DesenhadorProps } from "./data/cemweb";
import Tempera, { DesenhadorHandlers } from "./tempera";
import { useState } from "react";
import { createPortal } from "react-dom";
import { SVGConverter } from "./utils/exportSvgClass";
import { WebccRuntime } from "./data/interfaces/WebccInterfaces/WebccRuntime";

const divPortal = document.getElementById("desenhador");

export const Desenhador = forwardRef<DesenhadorHandlers, DesenhadorProps>((props, ref) => (
    <>
      {divPortal &&
        createPortal(
          <App>
            <Home ref={ref} {...props} />
          </App>,
          divPortal
        )}
    </>
));

export const DesenhadorTempera = forwardRef<DesenhadorHandlers, DesenhadorProps>((props, ref) => (
  <App>
      <Tempera ref={ref} {...props} />
  </App>
));

export function useToggleDisplay() {
	const [isDesenhadorOpen, setIsDesenhadorOpen] = useState(false);

	const toggleDisplayDesenhador = (shouldShow: boolean) => {
		window.scrollTo(0, 0);
		document.querySelectorAll("body > div").forEach((div) => {
			const element = div as HTMLElement;
			if(element.id.startsWith("rbd")) return;
			if (element.id !== "desenhador") {
				element.style.display = shouldShow ? "none" : "block";
			} else {
				element.style.display = shouldShow ? "block" : "none";
			}
		});
		setIsDesenhadorOpen(shouldShow);
	};

	return { isDesenhadorOpen, toggleDisplayDesenhador, setIsDesenhadorOpen };
}

export {SVGConverter};

export {WebccRuntime};