import React from "react";
import { theme } from "../styles/theme";
import { useMediaQuery } from "@mui/material";
import { ReactNode, createContext, useState } from "react";

export interface SidebarContextData {
	isLeftOpen: boolean;
  isRightOpen: boolean;
  leftTabIndex: number;
  rightTabIndex: number;
  toggleIsLeftOpen: Function;
  toggleIsRightOpen: Function;
  setLeftIndex: Function;
  setRightIndex: Function;
}

export const SidebarContext = createContext(
	{} as SidebarContextData,
);

interface SidebarProviderProps {
	children: ReactNode;
}

export function SidebarProvider({children}: SidebarProviderProps) {
    const [isLeftOpen, setIsLeftOpen] = useState(false);
    const [isRightOpen, setIsRightOpen] = useState(false);
    const [leftTabIndex, setLeftTabIndex] = useState(0);
    const [rightTabIndex, setRightTabIndex] = useState(0);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const toggleIsLeftOpen = () => {
        if(isSmallScreen && isRightOpen) {
          setIsRightOpen(false);
        }
        setIsLeftOpen(!isLeftOpen);
    };

    const toggleIsRightOpen = () => {
        if(isSmallScreen && isLeftOpen) {
          setIsLeftOpen(false);
        }
        setIsRightOpen(!isRightOpen);
    };

    const setLeftIndex = (index: number) => {
        setLeftTabIndex(index);
    };

    const setRightIndex = (index: number) => {
        setRightTabIndex(index);
    };

    const valuesContext = {
        isLeftOpen,
        isRightOpen,
        leftTabIndex,
        rightTabIndex,
        toggleIsLeftOpen,
        toggleIsRightOpen,
        setLeftIndex,
        setRightIndex,
    };

  return (
    <SidebarContext.Provider
      value={valuesContext}
    >
      {children}
    </SidebarContext.Provider>
  );
}